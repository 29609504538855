<template>
  <div id="aDeviceConfig" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
    <div class="c-container-box">
      <el-table :data="dataArrFilter">
        <el-table-column prop="title" label="设备名称"></el-table-column>
        <el-table-column prop="uid" label="设备UID"></el-table-column>
        <el-table-column prop="gpsConfig.location" label="GPS坐标"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="warning" icon="el-icon-map-location" circle size="mini" @click="showGpsDialig(scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :page-sizes="[10, 15, 20, 25, 30]" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="dataArr.length" v-show="dataArr.length>0" @size-change="sizeChange" @current-change="pageChange">
      </el-pagination>
    </div>
    <!-- 坐标填写 -->
    <Dialog :data="gpsDialog" @sure="sure">
      <div style="padding: 0 10%;">
        <div class="c-item">
          <span>设备名称(uid)：</span>
          <span>{{curDevice.name}}({{curDevice.uid}})</span>
        </div>
        <div class="c-item">
          <span>设备GPS坐标：</span>
          <el-input v-model="gpsDialog.data.location"></el-input>
        </div>
        <div class="c-item">
          <span></span>
          <a href="http://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank">去复制坐标</a>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../../components/Dialog.vue';

export default {
  data() {
    return {
      curDevice: {}, //当前操作的设备
      // 编辑dialog
      gpsDialog: {
        show: false,
        title: '坐标设置',
        width: '500px',
        data: {
          location: ''
        }
      },
      loading: false,
      // page
      page: {
        size: 10,
        curPage: 1
      }
    }
  },
  components: {
    Dialog
  },
  computed: {
    dataArr() {
      return this.$store.getters.getCurDevices;
    },
    dataArrFilter() {
      let page = this.page;
      let arr = this.dataArr.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    }
  },
  watch: {
    dataArr() {
      this.page.curPage = 1;
      this.dataRequest();
    }
  },
  methods: {
    dataRequest() {
      let uids = "";
      let devices = this.dataArr;
      this.dataArrFilter.forEach((item) => {
        uids += item.id + ","
      })
      let json = {
        "cmd": "dataStore",
        "id": uids,
        "tag": "gps"
      }
      this.ws.send(json);
    },

    // 编辑
    showGpsDialig(item) {
      this.curDevice = {
        uid: item.id,
        name: item.title
      }
      this.gpsDialog.show = true;
      this.gpsDialog.data = {
        location: item.gpsConfig.location
      }
    },
    // 确定
    sure() {
      let data = this.gpsDialog.data;
      if (!data.location) {
        this.common.cToast(this, '请输入GPS坐标');
        return;
      }
      if (!this.common.cVerifyGps(data.location)) {
        this.common.cToast(this, 'GPS坐标格式错误');
        return;
      }
      let json = {
        "cmd": "setDataStore",
        "id": this.curDevice.uid,
        "tag": "gps",
        "data": {
          "location": data.location,
        }
      }
      this.ws.send(json);
      this.loading = true;
    },

    // 分页——当前页改变
    sizeChange(size) {
      this.page.size = size;
      this.page.curPage = 1;
      this.dataRequest();
    },
    pageChange(curPage) {
      this.page.curPage = curPage;
      this.dataRequest();
    }
  },
  mounted() {
    this.dataRequest();

    this.ws.addCallback('dataStoreAck', (res) => {
      if (!this.common.cGetResult(res)) {
        return;
      }
      for (let item of res.items) {
        let data = item.data;
        if (JSON.stringify(data) != "{}") {
          let device = this.common.cFindObj(this.dataArr, "id", item.id);
          if (device != null) {
            device.gpsConfig = {
              location: data.location
            }
          }
        }
      }
    })

    this.ws.addCallback('setDataStoreAck', (res) => {
      this.loading = false;
      if (!this.common.cGetResult(res)) {
        this.common.cResultNo(this);
        return;
      }
      let device = this.common.cFindObj(this.dataArr, "id", res.id);
      if (device != null) {
        device.gpsConfig = {
          location: this.gpsDialog.data.location,
        }
      }
      this.common.cResultOk(this);
      this.gpsDialog.show = false;
    })
  }
}
</script>

<style lang="scss">
#aDeviceConfig {
  .c-container-box {
    margin-top: 0;
  }
}
</style>
